<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      แก้ไขทะเบียนรถ
    </v-card-title>
    <v-card-text>
      <v-row justify="center" class="mt-0">
        <v-col cols="4">
          <v-text-field
            class="pt-2 "
            name="customer_name"
            v-model="form.customer_name"
            label="ชื่อลูกค้า"
            outlined
            dense
            background-color="white"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="pt-2 "
            name="customer_phone_number"
            v-model="form.customer_phone_number"
            :class="{'as-password': !toggleShowPhoneNumber}"
            label="เบอร์โทร"
            @keydown="preventDelete"
            @keypress.native="isNumber"
            outlined
            dense
            background-color="white"
            @input="parsePhonePattern"
          />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-3">
        <v-col cols="4">
          <v-text-field
            name="customer_taxpayer_number"
            v-model="form.customer_taxpayer_number"
            label="เลขที่เสียภาษี"
            outlined
            dense
            hide-details
            @keypress.native="taxNumber"
            autocomplete="off"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            name="customer_address"
            label="ที่อยู่"
            outlined
            dense
            autocomplete="off"
            @click="
              (dialogAddressData = form.customer_address),
                (dialogAddress = true),
                (dialogAddressKey = 'customer_address')
            "
            :value="`${form.customer_address.address} ${form.customer_address.sub_district} ${form.customer_address.district} ${form.customer_address.province} ${form.customer_address.postal_code}`"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-0">
        <v-col cols="4">
          <v-text-field
            name="customer_company_branch"
            v-model="form.customer_company_branch"
            label="สาขา"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-textarea
            name="customer_note"
            v-model="form.customer_note"
            label="หมายเหตุลูกค้า"
            outlined
            dense
            rows="3"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-3">
        <v-col cols="4">
          <v-select
            v-model="form.discounted_groups_id"
            label="กลุ่มลูกค้า/ตัวแทน"
            outlined
            :items="discountedGroups"
            dense
            hide-details
            item-text="name"
            item-value="_id"
          ></v-select>
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row justify="center" class="mt-0">
        <v-col cols="4" class="d-flex mb-0 pb-0">
          <v-checkbox
            class="mt-0"
            v-model="form.is_delivery_address"
            dense
          />
          <v-text-field
            name="customer_delivery_address"
            label="ที่อยู่จัดส่ง"
            outlined
            dense
            autocomplete="off"
            @click="
              (dialogAddressData = form.customer_delivery_address),
                (dialogAddress = true),
                (dialogAddressKey = 'customer_delivery_address')
            "
            :value="`${form.customer_delivery_address.address} ${form.customer_delivery_address.sub_district} ${form.customer_delivery_address.district} ${form.customer_delivery_address.province} ${form.customer_delivery_address.postal_code}`"
            hide-details
          />
        </v-col>
        <v-col cols="4"></v-col>
      </v-row>
      <v-row justify="center" class="mt-0">
        <v-col cols="4">
          <v-checkbox
            class="mt-0"
            v-model="form.call_alert"
            label="โทรแจ้งเมื่องานเสร็จ"
          />
        </v-col>
        <v-col cols="4">
          <v-checkbox
            class="mt-0"
            v-model="form.call_remind_renewal"
            label="โทรเตือนต่ออายุ"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ยกเลิก
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import {
  numeric,
  integer,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';
import AddressDetail from '@/components/Address/AddressDetail.vue';

export default {
  components: {
    AddressDetail,
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    discountedGroups: {
      type: Array,
      default: []
    },
  },
  validations: {
    form: {
      postal_code: {
        numeric,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      }
    }
  },
  data() {
    return {
      loading: false,
      form: this.item,
      dialogAddressKey: '',
      dialogAddress: false,
      dialogAddressData: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      }, 
      list: {
        provinces: [],
      },
      permission: {},
      showPhoneNumber: false,
      transTimeout: null,
      toggleShowPhoneNumber: false,
      deleteOnce: false
    };
  },
  async created() {
    await this.initAsPassword()
    if(this.form.customer_phone_number == ""){
        this.toggleShowPhoneNumber = true
      }
    await this.getUserGroup()
  },
  watch: {
    item: function(newVal, oldVal) {
      this.form = newVal;
      this.initAsPassword()
      if(newVal.customer_phone_number == ""){
        this.toggleShowPhoneNumber = true
      }
      this.getUserGroup()
      this.$v.form.$reset();
    },
    'form.customer_phone_number': function (val){
      if(this.showPhoneNumber){
        return
      }
      if (this.transTimeout) {
        clearTimeout(this.transTimeout);
        this.transTimeout = null;
      }
      if(val.length == 0 ){
        this.toggleShowPhoneNumber = true
      } else if(val.length >= 10){
        this.transTimeout = setTimeout( () =>{
          this.toggleShowPhoneNumber = false
        }, 5000);
      }
    },
  },
  methods: {
    initAsPassword(){
      this.transTimeout = null
      this.toggleShowPhoneNumber = false
      this.deleteOnce = false
    },
    close() {
      this.initAsPassword()
      this.$emit('onClose');
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      
      this.$emit('onSubmit', this.form);
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;
        
        if (this.permission.show_phone_number == 0) {
          this.showPhoneNumber = false;
          this.toggleShowPhoneNumber = false;
        } else {
          this.showPhoneNumber = true;
          this.toggleShowPhoneNumber = true;
        };
      } catch (error) {
        this.showPhoneNumber = true;
        this.toggleShowPhoneNumber = true;
        console.error('Error in getUserGroup:', error);
      };
    },
    preventDelete(evt) {
      let arrowKey = [37, 38, 39, 40];
      let selectStart = evt.target.selectionStart;
      let selectEnd = evt.target.selectionEnd;
      if(!this.showPhoneNumber && !this.deleteOnce){
        this.deleteOnce = true
        this.form.customer_phone_number = ""
        return
      }
      if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (evt.target.value[selectStart - 1] == '-') {
          setTimeout(
            function () {
              this.form.customer_phone_number = this.parsePhonePattern(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
              evt.target.value = this.parsePhonePattern(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
            }.bind(this),
            0
          );
        }
      } else {
        if (evt.target.value.length >= 12 && !arrowKey.includes(evt.keyCode)) {
          evt.preventDefault();
        } else {
          this.selectStart = -1;
        }
      }
    },
    parsePhonePattern(value, returnValue = false) {
      let position = value
        .substr(0, 12)
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,7})/);
      let phoneNumber = '';
      phoneNumber = position[1];

      if (position[1].length >= 3) {
        phoneNumber += '-';
      }

      phoneNumber += position[2];

      if (returnValue) {
        return phoneNumber;
      } else {
        this.form.customer_phone_number = phoneNumber;
        if (this.selectStart > -1) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = this.selectStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        } else if (window.event.target.selectionStart < value.length) {
          let input = document.getElementById('customer_phone_number');
          let selectionStart = window.event.target.selectionStart;
          console.log(selectionStart);
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart, 'forward');
          }, 0);
        }
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.customer_phone_number.length >= 11 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onSubmitAddress(val) {
      this.form[this.dialogAddressKey] = val;
      this.dialogAddress = false;
    },
    taxNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.customer_taxpayer_number.length >= 13 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
