<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-10 primary--text">
      แก้ไขทะเบียนรถ
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="3">
          <v-text-field
            class="pt-2 "
            name="plate_character"
            v-model="form.plate_character"
            label="หมวด"
            outlined
            dense
            background-color="white"
          />
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="pt-2 "
            name="plate_number"
            v-model="form.plate_number"
            label="เลขทะเบียน"
            outlined
            dense
            background-color="white"
          />
        </v-col>
        <v-col cols="3" class="pt-5">
          <v-select
            v-model="form.car_province"
            :items="provinces"
            label="จังหวัด"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <div class="red--text text-center">
        ข้อมูลรถอื่นๆ แก้ไขไม่ได้ ต้องสร้างบิลใหม่เท่านั้น
      </div>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer />
      <v-btn outlined class="px-5 mr-10" color="grey" @click="close()">
        ยกเลิก
      </v-btn>
      <v-btn
        class="px-10 mr-15"
        color="primary"
        @click="save()"
        :loading="loading"
        :disabled="loading"
      >
        บันทึกข้อมูล
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  numeric,
  integer,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    provinces: {
      type: Array,
      default: []
    }
  },
  validations: {
    form: {
      postal_code: {
        numeric,
        integer,
        minLength: minLength(5),
        maxLength: maxLength(5)
      }
    }
  },
  data() {
    return {
      loading: false,
      form: this.item,
      list: {
        provinces: [],
      }
    };
  },
  async created() {
    
  },
  watch: {
    item: function(newVal, oldVal) {
      this.form = newVal;
      this.$v.form.$reset();
    }
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.form.car_plate = this.form.plate_character+' '+this.form.plate_number
      this.$emit('onSubmit', this.form);
    },
  },
  computed: {
    isUpdate() {
      return this.item;
    }
  }
};
</script>
