<template>
  <span>
    <span @click="print">
      <slot></slot>
    </span>
    <div style="display: none">
      <div id="printJS-form9inc">
        <template v-for="(docPage, doc_page_index) in form">
          <div :key="doc_page_index">
            <template v-if="form[doc_page_index].copy_product.length">
              <table :border="tableBorder" width="100%">
                <tbody>
                  <table :border="tableBorder" width="100%">
                    <tbody>
                      <td style="width: 60%">
                        <div valign="top" class="border-radius">
                          <div class="text-header">
                            {{ $store.state.selected_branch.branch_name }} โทร.
                            {{ form[doc_page_index].phone_number }}
                          </div>
                          <table :border="tableBorder" width="100%">
                            <tbody>
                              <tr>
                                <td>
                                  เปิดทุกวัน
                                  {{ form[doc_page_index].open_hours }}
                                </td>

                                <td>
                                  <tr>
                                    <td
                                      align="right"
                                      width="50%"
                                      v-if="form[doc_page_index].facebook"
                                    >
                                      Facebook:
                                      {{ form[doc_page_index].facebook || '-' }}
                                    </td>
                                    <td
                                      align="right"
                                      width="50%"
                                      v-if="form[doc_page_index].line_id"
                                    >
                                      Line ID:
                                      {{ form[doc_page_index].line_id || '-' }}
                                    </td>
                                  </tr>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ form[doc_page_index].location.address }}
                                  <template
                                    v-if="
                                      form[doc_page_index].location
                                        .sub_district != ''
                                    "
                                    >ต.{{
                                      form[doc_page_index].location.sub_district
                                    }}</template
                                  >
                                  <template
                                    v-if="
                                      form[doc_page_index].location.district !=
                                      ''
                                    "
                                  >
                                    อ.{{
                                      form[doc_page_index].location.district
                                    }}</template
                                  >
                                  <template
                                    v-if="
                                      form[doc_page_index].location.province !=
                                      ''
                                    "
                                  >
                                    จ.{{
                                      form[doc_page_index].location.province
                                    }}</template
                                  >
                                  {{
                                    form[doc_page_index].location.postal_code
                                  }}
                                </td>
                                <td>
                                  {{ form[doc_page_index].name_for_receipt }}
                                  {{ form[doc_page_index].company_branch }}
                                  {{ form[doc_page_index].taxpayer_number }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      &nbsp;
                      <td width="40%" valign="top">
                        <div align="center" class="text-header">
                          {{ documentName }}
                        </div>
                        <table :border="tableBorder" width="100%">
                          <tbody>
                            <tr>
                              <td>เลขที่ {{ form[doc_page_index].number }}</td>
                              <td>
                                วันที่
                                {{
                                  formatDate(form[doc_page_index].date_of_issue)
                                }}
                                {{
                                  form[doc_page_index].date_of_issue
                                    | moment($formatTime)
                                }}น.
                              </td>
                            </tr>
                            <tr>
                              <td>
                                ชื่อพนักงาน {{ form[doc_page_index].approval }}
                              </td>
                              <td>
                                กลุ่มลูกค้า
                                {{ form[doc_page_index].discounted_groups_id }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tbody>
                  </table>
                </tbody>
              </table>
              <br />
              <table :border="tableBorder" width="100%">
                <tbody>
                  <td style="width: 40%" valign="top">
                    <div
                      v-if="form[doc_page_index].customer_taxpayer_number"
                      class="detail-sub"
                    >
                      ชื่่อผู้จ่ายเงิน {{ form[doc_page_index].customer_name }}
                      &nbsp;
                      {{ form[doc_page_index].customer_taxpayer_number }}
                    </div>
                    <div v-else class="detail-sub">
                      คุณ {{ form[doc_page_index].customer_name }}
                      &nbsp;
                      {{ form[doc_page_index].customer_taxpayer_number }}
                    </div>
                    <div class="detail-sub">
                      {{ form[doc_page_index].customer_address.address }}
                      <template
                        v-if="
                          form[doc_page_index].customer_address.sub_district !=
                          ''
                        "
                      >
                        ต.{{
                          form[doc_page_index].customer_address.sub_district
                        }}</template
                      >
                      <template
                        v-if="
                          form[doc_page_index].customer_address.district != ''
                        "
                      >
                        อ.{{
                          form[doc_page_index].customer_address.district
                        }}</template
                      >
                      <template
                        v-if="
                          form[doc_page_index].customer_address.province != ''
                        "
                      >
                        จ.{{
                          form[doc_page_index].customer_address.province
                        }}</template
                      >
                      {{ form[doc_page_index].customer_address.postal_code }}
                    </div>
                    <div>
                      <template
                        v-if="
                          form[doc_page_index].customer_company_branch != ''
                        "
                        >สาขา
                        {{
                          form[doc_page_index].customer_company_branch
                        }}</template
                      >
                    </div>
                    <div
                      class="subtitle"
                      v-if="setting.show_customer_phone_number"
                    >
                      โทร:
                      {{
                        permission.show_phone_number 
                        ? form[doc_page_index].customer_phone_number
                        : form[doc_page_index].customer_phone_number.substring(0, form[doc_page_index].customer_phone_number.length-4) + 'XXXX'
                      }}
                    </div>
                  </td>
                  <td style="width: 60%" valign="top">
                    <div class="text-project">
                      {{ form[doc_page_index].car_plate }}
                      {{ form[doc_page_index].car_province }}
                      {{ setting.show_project_name ? form[doc_page_index].project_name : '' }}
                      {{
                        form[doc_page_index].call_alert
                          ? 'โทรแจ้งลูกค้าด้วย'
                          : ''
                      }}
                    </div>
                    <div>
                      วันจดทะเบียนรถ
                      {{ formatDate(form[doc_page_index].registration_date) }}
                      ชนิดเอกสาร
                      {{
                        form[doc_page_index].is_original_document
                          ? 'เล่มจริง'
                          : 'สำเนา'
                      }}
                    </div>

                    <span class="text-project">
                      วันที่นัดรับงาน
                      {{ formatDate(form[doc_page_index].pick_up_date) }}
                      {{ form[doc_page_index].pick_up_time }}
                      เป็นต้นไป</span
                    >
                  </td>
                </tbody>
              </table>
              <br />
              <table
                :border="tableBorder"
                width="100%"
                class="table line line-b"
              >
                <tbody>
                  <tr>
                    <td class="table-col-start" align="center" width="5%">
                      <div class="text-product">#</div>
                    </td>
                    <td class="table-td" align="center" width="50%">
                      <div class="text-product">รายละเอียด</div>
                    </td>
                    <td
                      class="table-td"
                      align="right"
                      width="10%"
                      v-show="form[doc_page_index].total_discount > 0"
                    >
                      <div class="text-product">ราคา</div>
                    </td>
                    <td
                      class="table-td"
                      align="right"
                      width="10%"
                      v-show="form[doc_page_index].total_discount > 0"
                    >
                      <div class="text-product">ส่วนลด</div>
                    </td>
                    <td
                      v-if="setting.vat_registration"
                      class="table-td"
                      align="right"
                      width="10%"
                    >
                      <div class="text-product">Vat</div>
                    </td>
                    <td class="table-col-end" align="right" width="15%">
                      <div class="text-product">ราคารวม</div>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, i) in form[doc_page_index].original_product
                      .length != 0
                      ? form[doc_page_index].original_product
                      : form[doc_page_index].copy_product"
                    :key="i"
                  >
                    <td class="table-col-start" align="center">
                      {{ i + 1 }}
                    </td>
                    <td class="table-td">
                      <div v-if="item['start_date'] !== undefined">
                        <div
                          class="text-product"
                          v-if="
                            setting.show_3rd_insurance_protection_date &&
                            item['category_id'] == 'PC1'
                          "
                        >
                          พ.ร.บ {{ item.name }}
                        </div>
                        <div v-else class="text-product">
                          {{ item.name }}
                        </div>
                        <div
                          class="detail"
                          v-if="
                            setting.show_3rd_insurance_protection_date &&
                            item['category_id'] == 'PC1'
                          "
                        >
                          คุ้มครองวันที่
                          {{ formatDate(item.start_date) }}
                          -
                          {{ formatDate(item.end_date) }}
                        </div>
                        <div
                          class="detail"
                          v-if="
                            setting.show_insurance_protection_date &&
                            item['category_id'] == 'PC2'
                          "
                        >
                          คุ้มครองวันที่
                          {{ formatDate(item.start_date) }}
                          -
                          {{ formatDate(item.end_date) }}
                        </div>
                      </div>
                      <div v-else>
                        <div class="d-inline-flex text-product">
                          {{ item.name }}
                        </div>
                        <div class="detail d-inline-flex text-product">
                          &nbsp;{{ item.detail }}
                        </div>
                      </div>
                    </td>
                    <td
                      class="table-td text-product"
                      align="right"
                      v-show="form[doc_page_index].total_discount > 0"
                    >
                      {{ item.product_price | numeral('0,0.00') }}
                    </td>
                    <td
                      class="table-td text-product"
                      align="right"
                      v-show="form[doc_page_index].total_discount > 0"
                    >
                      {{ item.discount | numeral('0,0.00') }}
                    </td>
                    <td
                      class="table-td text-product"
                      align="right "
                      v-if="setting.vat_registration"
                    >
                      {{ item.vat_price | numeral('0,0.00') }}
                    </td>
                    <td class="table-col-end text-product" align="right">
                      {{ item.price | numeral('0,0.00') }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table :border="tableBorder" width="100%">
                <tbody>
                  <tr>
                    <td style="width: 35%" valign="top">
                      <tr>
                        <div class="subtitle">
                          <span> หมายเหตุ</span> {{ form[doc_page_index].note }}
                        </div>
                        <p></p>
                      </tr>
                      <tr>
                        <div
                          v-if="
                            form[doc_page_index].delivery_address.postal_code &&
                            form[doc_page_index].customer_is_delivery_address
                          "
                        >
                          <div class="subtitle">
                            <span>ที่อยู่จัดส่ง</span>
                            {{ form[doc_page_index].delivery_address.address }}
                            <template
                              v-if="
                                form[doc_page_index].delivery_address
                                  .sub_district != ''
                              "
                            >
                              ต.{{
                                form[doc_page_index].delivery_address
                                  .sub_district
                              }}</template
                            >
                            <template
                              v-if="
                                form[doc_page_index].delivery_address
                                  .district != ''
                              "
                            >
                              อ.{{
                                form[doc_page_index].delivery_address.district
                              }}</template
                            >
                            <template
                              v-if="
                                form[doc_page_index].delivery_address
                                  .province != ''
                              "
                            >
                              จ.{{
                                form[doc_page_index].delivery_address.province
                              }}</template
                            >
                            {{
                              form[doc_page_index].delivery_address.postal_code
                            }}
                          </div>
                        </div>
                      </tr>
                      <br v-for="(br, brindex) in 2" :key="brindex" />
                      <tr>
                        <div v-if="documentName == 'ใบเสร็จรับเงิน'">
                          <div class="subtitle">
                            ชำระ:
                            <span
                              class="subtitle"
                              v-if="form[doc_page_index].payment.type == 1"
                            >
                              เงินสด
                            </span>
                            <span
                              class="subtitle"
                              v-if="form[doc_page_index].payment.type == 2"
                            >
                              โอน
                            </span>
                            <span
                              class="subtitle"
                              v-if="form[doc_page_index].payment.type == 3"
                            >
                              บัตร
                            </span>
                            {{ form[doc_page_index].payment.reference || '' }}
                            {{ summary.payment_amount[doc_page_index] | numeral('0,0.00') }}
                            บาท
                            <span v-if="form[doc_page_index].payment.cashier_name!==''">
                              ผู้รับเงิน:
                              {{
                                form[doc_page_index].payment.cashier_name || '-'
                              }}
                            </span>
                          </div>
                        </div>
                      </tr>
                    </td>
                    <td
                      style="width: 40%"
                      valign="top"
                      v-if="
                        form[doc_page_index].remain_invoice.length > 1 &&
                        form[doc_page_index].remain_invoice.length < 3
                      "
                    >
                      <div
                        v-if="documentName == 'ใบเสร็จรับเงิน'"
                        class="text-left detail"
                      >
                        ใบแจ้งหนี้ ที่รอการชำระ
                      </div>
                      <div
                        v-if="documentName == 'ใบแจ้งหนี้'"
                        class="text-left detail"
                      >
                        ใบแจ้งหนี้ทั้งหมดของโปรเจ็คนี้
                      </div>
                      <table :border="tableBorder" width="100%">
                        <tbody>
                          <tr>
                            <td align="center" class="detail" style="width: 5%">
                              #
                            </td>

                            <td
                              align="center"
                              class="detail"
                              style="width: 20%"
                            >
                              เลขที่
                            </td>
                            <td
                              align="center"
                              class="detail"
                              style="width: 25%"
                            >
                              ชำระก่อน
                            </td>

                            <td
                              align="center"
                              class="detail"
                              style="width: 25%"
                            >
                              ราคารวม
                            </td>

                            <td
                              align="center"
                              class="detail"
                              style="width: 25%"
                            >
                              สถานะ
                            </td>
                          </tr>
                          <tr
                            v-for="(invoice, invoice_index) in form[
                              doc_page_index
                            ].remain_invoice"
                            :key="invoice_index"
                          >
                            <td align="center" class="detail" style="width: 5%">
                              {{ invoice_index + 1 }}
                            </td>
                            <td
                              align="center"
                              class="detail"
                              style="width: 30%"
                            >
                              {{ invoice.number }}
                            </td>
                            <td
                              align="center"
                              class="detail"
                              style="width: 25%"
                            >
                              {{ formatDate(invoice.due_date) }}
                            </td>
                            <td
                              align="center"
                              class="detail"
                              style="width: 10%"
                            >
                              {{ invoice.net_price | numeral('0,0.00') }}
                            </td>
                            <td
                              align="center"
                              class="detail"
                              style="width: 30%"
                            >
                              {{
                                invoice.status == 0 ? 'ค้างจ่าย' : 'จ่ายแล้ว'
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="text-center detail">
                        ยอดรวม
                        {{
                          form[doc_page_index].remain_net_price
                            | numeral('0,0.00')
                        }}
                      </div>
                    </td>
                    <td valign="top" style="width: 30%">
                      <div>
                        <table :border="tableBorder" width="100%">
                          <tbody v-if="form[doc_page_index].document_type >= 3">
                            <tr>
                              <td align="right" width="50%">
                                <div>มูลค่าตามเอกสารเดิม</div>
                              </td>
                              <td align="right" width="50%">
                                <div>
                                  {{
                                    form[doc_page_index].original_document_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td align="right" width="50%">
                                <div>มูลค่าที่ถูกต้อง</div>
                              </td>
                              <td align="right" width="50%">
                                <div>
                                  {{
                                    form[doc_page_index].total_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td align="right" width="50%">
                                <div>ผลต่าง</div>
                              </td>
                              <td align="right" width="50%">
                                <div>
                                  {{
                                    form[doc_page_index].differrence_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>

                            <tr
                              v-show="form[doc_page_index].total_discount > 0"
                            >
                              <td align="right" width="50%">
                                <div>รวม</div>
                              </td>
                              <td align="right" width="50%">
                                <div>
                                  {{
                                    form[doc_page_index].differrence_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr
                              v-show="form[doc_page_index].total_discount > 0"
                            >
                              <td align="right">
                                <div>ส่วนลด</div>
                              </td>
                              <td align="right">
                                <div>
                                  {{
                                    form[doc_page_index].total_discount
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>

                            <tr v-if="setting.vat_registration">
                              <td align="right">
                                <div>Vat</div>
                              </td>
                              <td align="right">
                                <div>
                                  {{
                                    form[doc_page_index].vat_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td align="right">
                                <div><br /></div>
                              </td>
                              <td align="right"><div></div></td>
                            </tr>
                            <tr>
                              <td align="right">
                                <div>รวมทั้งสิ้น</div>
                              </td>
                              <td align="right">
                                <div>
                                  {{
                                    form[doc_page_index].net_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                          </tbody>

                          <!-- Quotation, Invoice, Receipt -->
                          <tbody v-if="form[doc_page_index].document_type < 3">
                            <tr v-show="form[doc_page_index].total_discount > 0 || form[doc_page_index].vat_price > 0">
                              <td align="right" width="60%">
                                <div class="detail-sub">รวมเป็นเงิน</div>
                              </td>
                              <td align="right" width="40%">
                                <div class="detail-sub">
                                  {{
                                    form[doc_page_index].total_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr
                              v-show="form[doc_page_index].total_discount > 0"
                            >
                              <td align="right">
                                <div class="detail-sub">ส่วนลดรวม</div>
                              </td>
                              <td align="right">
                                <div class="detail-sub">
                                  {{
                                    form[doc_page_index].total_discount
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>

                            <tr v-if="setting.vat_registration">
                              <td align="right">
                                <div class="detail-sub">Vat</div>
                              </td>
                              <td align="right">
                                <div class="detail-sub">
                                  {{
                                    form[doc_page_index].vat_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td align="right">
                                <div class="subtitle">จำนวนเงินรวมทั้งสิ้น</div>
                              </td>
                              <td align="right">
                                <div class="subtitle">
                                  {{
                                    form[doc_page_index].net_price
                                      | numeral('0,0.00')
                                  }}
                                  บาท
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                v-if="
                  documentName == 'ใบเพิ่มหนี้' || documentName == 'ใบลดหนี้'
                "
              >
                <table :border="tableBorder" width="100%">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 80%">
                        <div>
                          <span v-if="documentName == 'ใบเพิ่มหนี้'">
                            ชำระ:
                          </span>
                          <span v-if="documentName == 'ใบลดหนี้'">
                            คืนเงิน:
                          </span>
                          <label v-if="form[doc_page_index].payment.type == 1">
                            เงินสด
                          </label>
                          <label v-if="form[doc_page_index].payment.type == 2">
                            โอน
                          </label>
                          <label v-if="form[doc_page_index].payment.type == 3">
                            บัตร
                          </label>
                          <label>
                            รายละเอียด:
                            {{ form[doc_page_index].payment.reference || '-' }}
                          </label>
                          <label>
                            จำนวนเงิน
                            {{
                              form[doc_page_index].net_price | numeral('0,0.00')
                            }}
                            บาท
                          </label>
                          <label v-if="form[doc_page_index].payment.cashier_name!==''">
                            <span v-if="documentName == 'ใบเพิ่มหนี้'">
                              ผู้รับเงิน:
                            </span>
                            <span v-if="documentName == 'ใบลดหนี้'">
                              ผู้คืนเงิน:
                            </span>
                            {{
                              form[doc_page_index].payment.cashier_name || '-'
                            }}
                          </label>
                          <br />
                        </div>
                      </td>
                      <td align="center" style="width: 30%"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <table
                :border="tableBorder"
                width="100%"
                style="margin-top: -300px"
              >
                <tbody>
                  <tr>
                    <td align="center" style="width: 22%">
                      <div>
                        {{ signatureLeft }}.................................
                      </div>
                    </td>

                    <td style="text-align: center width: 44%"><div></div></td>

                    <td align="center" style="width: 22%">
                      <div>
                        {{ signatureRight }}.................................
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </template>
      </div>
    </div>
  </span>
</template>

<script>
import printJS from 'print-js';
//import QRCode from 'qrcode';
import * as moment from 'moment';

export default {
  props: {
    type: {
      required: true,
      type: String,
      default: 'ใบแจ้งหนี้', //  1 ใบเสนอราคา, 2 ใบแจ้งหนี้, 3 ใบเสร็จรับเงิน, 4 ใบเพิ่มหนี้, 5 ใบลดหนี้
    },
    data: {
      required: false,
      type: Object,
      default: null,
    },
    data2: {
      required: false,
      type: Object,
      default: null,
    },
    docTypeId: {
      required: false,
      type: Number,
      default: 0,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    firstCreated: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tableBorder: 0,
      setting: '',
      permission: {},
      temp: null,
      hasSplitBill: false,
      total_price: 0,
      summary: {
        payment_amount: [],
        remain_change: [],
      },
      form: [
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
        },
        /* form 2 */
        {
          number: '',
          reference_document: '',
          docType: '',
          approval: '',
          customer_name: '',
          customer_phone_number: '',
          customer_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_taxpayer_number: '',
          customer_company_branch: '',
          customer_is_delivery_address: false,
          delivery_address: {
            address: '',
            district: '',
            sub_district: '',
            province: '',
            postal_code: '',
          },
          customer_group: '',
          call_alert: false,
          call_remind_renewal: false,
          customer_note: '',
          car_plate_character: '',
          car_plate_number: '',
          car_plate_province: '',
          car_type: 'CT1',
          registration_date: '',
          tax_filling_date: '',
          is_corporation_car: false,
          extra_fuel: 0,
          car_weight: '',
          engine_size: '',
          is_original_document: false,
          taxout_date: '',
          branch_id: '',
          document_type: 0,
          customer_branch_office: '',
          date_of_issue: '',
          due_date: '',
          project_name: '',
          pick_up_date: '',
          pick_up_time: '',
          tax_year_init: '',
          tax_year_ended: '',
          copy_product: [],
          original_product: [],
          original_document_price: 0,
          total_price: 0,
          total_discount: 0,
          net_price: 0,
          notation: '',
          note: '',
          discounted_groups_id: '',
          logo_url: '',
          name_for_receipt: '',
          company_branch: '',
          location: {
            address: '',
            district: '',
            postal_code: '',
            province: '',
            sub_district: '',
          },
          payment: {
            amout: 0,
            date: '',
            note: '',
            reference: '',
            type: 0,
          },
          taxpayer_number: '',
          phone_number: '',
          facebook: '',
          line_id: '',
          open_hours: null,
          //deadlines:'Deadlines',
          qr_code: 'http://via.placeholder.com/100x100',
        },
      ],
    };
  },

  created() {
    this.getUserGroup();
    console.log(this.data);

    if (this.data) {
      this.form[0] = { ...this.form[0], ...this.data };
    }
    if (this.data2) {
      this.hasSplitBill = true;
      this.form[1] = { ...this.form[1], ...this.data2 };
      if (this.form[1].logo_url == '')
        this.form[1].logo_url = this.form[0].logo_url;
      this.total_price = this.form[0].net_price + this.form[1].net_price;
    } else {
      this.form.pop(this.form[1]);
    }
    this.getBranchSetting();

    this.summary.payment_amount.push(
      this.hasSplitBill ? this.form[0].net_price : this.form[0].payment.amount
    );
    this.summary.payment_amount.push(
      this.hasSplitBill
        ? this.form[1].payment.amount - this.form[0].net_price
        : 0
    );
    this.summary.remain_change.push(
      this.hasSplitBill
        ? 0
        : this.form[0].payment.amount - this.form[0].net_price
    );
    this.summary.remain_change.push(
      this.hasSplitBill ? this.form[1].payment.amount - this.total_price : 0
    );
  },

  methods: {
    async getBranchSetting() {
      let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
      });
      this.setting = listFiltered[0].setting;
        if (this.setting.print_one_page && this.data2) {
          this.temp = [...this.form[1].original_product];
          this.form[1].original_product = [...this.form[1].copy_product];
          this.form[1].copy_product = [...this.temp];
        }
        if (this.setting.show_late_tax_fine && this.firstCreated) {
          for (var i = 0; i < this.form.length; i++) {
            for (var j = 0; j < this.form[i].copy_product.length; j++) {
              if (this.form[i].copy_product[j].tag == 'tax_include_fines') {
                this.form[i].copy_product[j].name = this.form[i].copy_product[
                  j
                ].name.concat(' ', this.form[i].copy_product[j].print_detail);
                break;
              }
            }
            if (!this.setting.is_split_bill) {
              for (var j = 0; j < this.form[i].original_product.length; j++) {
                if (
                  this.form[i].original_product[j].tag == 'tax_include_fines'
                ) {
                  var price = this.form[i].original_product[j].price;
                  this.form[i].original_product[j].name = this.form[
                    i
                  ].original_product[j].name.concat(
                    ' ',
                    this.form[i].original_product[j].print_detail
                  );
                  break;
                }
              }
            }
          }
          this.firstCreated = false;
        }
    },
    print() {
      if (!this.disabled) {
        // var canvas1 = document.getElementById('canvas11');
        // var canvas2 = document.getElementById('canvas13');
        // if(this.data2){
        //   var canvas3 = document.getElementById('canvas21');
        //   var canvas4 = document.getElementById('canvas23');
        // }
        if (
          this.documentName == 'ใบแจ้งหนี้' ||
          this.documentName == 'ใบเสร็จรับเงิน'
        ) {
          // QRCode.toCanvas(canvas1, this.form[0].qr_code, (error) => {});
          // QRCode.toCanvas(canvas2, this.form[0].qr_code, (error) => {});
          // if(this.data2){
          //   QRCode.toCanvas(canvas3, this.form[1].qr_code, (error) => {});
          //   QRCode.toCanvas(canvas4, this.form[1].qr_code, (error) => {});
          // }
          setTimeout(() => {
            this.createForm();
          }, 0.5);
        } else {
          this.createForm();
        }
      }
    },
    createForm() {
      printJS({
        printable: 'printJS-form9inc',
        type: 'html',
        // type:'pdf',
        // header: 'PrintJS - Form Element Selection',
        font_size: '11px;',
        // font_size: '11px;',
        maxWidth: '100%',
        style: `
            @page {  size: 8.5in 5.5in; margin-top: 0.5cm; }
            .primary-color { color: ${this.primaryColor}; }
            .alert { color: #FF5252; }
            .primary-border-radius { border-radius: 5px; border: solid 1px ${this.primaryColor}; padding: 3px 10px 3px 10px;}
            .border-radius { border-radius: 5px; border: solid 2px #ccc; padding: 0px 10px 10px 10px;}
            .line { border-top: solid #ccc 1px; }
            .line-b { border-bottom: solid #ccc 1px; }
            .text-header { font-size: 20px!important; }
            .text-project { font-size: 16px!important; }
            .table-td {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-left: none!important;
              border-right: none!important;
            } 
            .table-bottom {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-top: none!important;
              border-left: none!important;
              border-right: none!important;
            }
            .table-col-start {
              border: 1px solid #ccc !important;
              border-collapse: collapse;
              border-right: none!important;
            }
            .table-col-end {
             border: 1px solid #ccc !important;
             border-collapse: collapse;
             border-left: none!important;
            }
            .font-bold { font-weight: bold }
            .text-product{ font-size: 14px!important; }
            .subtitle {
              font-size: 12px!important; ;
            }
            .detail-sub { font-size: 10px!important; }
            .detail { font-size: 12px!important; }
            table, th, td {
              border-collapse: collapse;
            }
            .note {
              font-size: 10px!important;
            }
             .text-note {
             color: #FF5252; font-size: 12px!important;
            }
            .text-center {text-align: center;}
            .pagebreak { page-break-before: always; }
            `,
      });
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    async getUserGroup() {
      try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
            console.log("No getGroupPermissions")
        };

        this.permission.show_phone_number = this.$store.state.group_permissions.show_phone_number;

      } catch (error) {
        console.error('Error getUserGroup in print:', error);
        Sentry.captureException(error);
        this.permission.show_phone_number = true;
      };
    },
  },
  computed: {
    documentName() {
      if (this.type == 1) return 'ใบเสนอราคา';
      else if (this.type == 2) return 'ใบแจ้งหนี้';
      else if (this.type == 3) return 'ใบเสร็จรับเงิน';
      else if (this.type == 4) return 'ใบเพิ่มหนี้';
      else if (this.type == 5) return 'ใบลดหนี้';
      return this.type;
    },
    primaryColor() {
      if (this.type == 'ใบเสนอราคา') return '#7225dd';
      else if (this.type == 'ใบแจ้งหนี้') return '#d86f07';
      else if (this.type == 'ใบเสร็จรับเงิน') return '#048bff';
      else if (this.type == 'ใบเพิ่มหนี้') return 'red';
      else if (this.type == 'ใบลดหนี้') return '#49aa1c';
    },
    signatureLeft() {
      if (this.type == 'ใบเสนอราคา') return 'ผู้สั่งซื้อ';
      else if (this.type == 'ใบแจ้งหนี้') return 'ผู้รับแจ้งหนี้';
      else if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้จ่ายเงิน';
      else if (this.type == 'ใบเพิ่มหนี้') return 'ผู้จ่ายเงิน';
      else if (this.type == 'ใบลดหนี้') return 'ผู้จ่ายเงิน';
    },
    signatureRight() {
      if (this.type == 'ใบเสนอราคา') return 'ผู้เสนอราคา';
      else if (this.type == 'ใบแจ้งหนี้') return 'ผู้แจ้งหนี้';
      else if (this.type == 'ใบเสร็จรับเงิน') return 'ผู้รับเงิน';
      else if (this.type == 'ใบเพิ่มหนี้') return 'ผู้เสนอราคา';
      else if (this.type == 'ใบลดหนี้') return 'ผู้เสนอราคา';
    },
  },
};
</script>

<style lang="scss">
#printJS-form9inc {
  position: relative;
}
</style>
